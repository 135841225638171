.expense-item-wrapper{
    border-radius: 12px;
    background-color: #202020;
    padding: 20px 40px;
    margin: auto;
}

@media only screen and (min-width: 800px){
    .expense-item-wrapper{
        width: 800px;
    }
}

@media only screen and (max-width: 800px){
    .expense-item-wrapper{
        padding: 20px 20px;
    }
}