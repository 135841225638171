.new-expense__controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
  }
  .new-expense__control{
    width: 48%;
    margin-bottom: 1rem;
  }
  .new-expense__control.w-100{
    width: 100%;
  }
  .new-expense__control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
    color: #ccc;
  }
  
  .new-expense__control input {
    font: inherit;
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
  }
  .new-expense__actions {
    text-align: right;
  }
  .new-expense__actions button {
    border: 1px solid #ccc;
  }
  .error{
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
    color: #ff2727;
  }


@media only screen and (max-width: 800px){
  .new-expense__actions {
    text-align: center;
  }
  .new-expense__actions button {
    margin-bottom: 10px;
  }
  .new-expense__control{
    width: 100%;
  }

}